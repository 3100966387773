import React from "react"

import * as styles from '../styles/pages/privacyPolicy.module.scss'
import { Layout } from "../components/layout"
import { SEO } from "../components/SEO"

const PrivacyPolicy = ({ location }) => {
  return (
    <Layout
      isHome={false}
      isLargeFooter={false}
      location={location}
      whiteHeader={false}
    >

      <SEO title="Roberty Automation - Política de privacidade" />

      <section className={styles.page}>
        <h1>Politica de privacidade do Roberty</h1>

        <p>Última atualização em 20 de julho de 2021.</p>
        <br />
        <br />

        <p>
          O Roberty está empenhada em proteger as suas informações pessoais e o seu direito à
          privacidade, você tem direito de acessar, corrigir, portar, eliminar seus dados, além de
          confirmar que tratamos os seus dados. Se tiver quaisquer questões ou preocupações sobre este
          aviso de privacidade ou sobre as nossas práticas em relação às suas informações pessoais,
          por favor contate-nos em {` `}
          <a href="mailto:dpo@roberty.app">dpo@roberty.app</a>.
        </p>
        <p>
          Quando você visita o nosso site {` `}
          <a href="https://studio.roberty.app/">https://studio.roberty.app/</a>, usa qualquer um dos
          nossos serviços, agradecemos o fato de você estar nos confiando suas informações pessoais.
          Nós levamos sua privacidade muito a sério. Neste aviso de privacidade, procuramos explicar
          da forma mais clara possível quais informações coletamos, como as usamos e quais direitos
          você tem em relação a elas. Esperamos que você reserve um tempo para lê-lo com atenção, pois
          é importante. Se houver quaisquer termos neste aviso de privacidade com os quais você não
          concorda, interrompa o uso de nossos serviços imediatamente.
        </p>
        <p>
          Esta política privacidade se aplica a todas as informações coletadas por meio de nossos
          serviços bem como quaisquer serviços, recursos e software.
        </p>
        <br />
        <h2>Informações que coletamos</h2>
        <p>
          Coletamos informações pessoais que você voluntariamente nos fornece quando se registra no
          nosso website, manifesta interesse em obter informações sobre nós ou nossos produtos e
          serviços, quando você realiza interações no website ou de outra forma quando você nos
          contatar.
        </p>
        <p>
          As informações pessoais que coletamos dependem do contexto de suas interações conosco e com
          o website, as escolhas que você faz e os produtos e recursos que usa.
        </p>
        <p>
          As informações pessoais que coletamos podem incluir o seguinte:
          <br />
          Informações pessoais fornecidas por você. Nós coletamos nomes; endereço de e-mail; números
          de telefone; nomes de usuário; senhas; áudio do computador; dados da gravação da área de
          trabalho; e outras informações semelhantes.
        </p>
        <p>
          Todas as informações pessoais que você nos fornece devem ser verdadeiras, completas e
          precisas, e você deve nos notificar sobre quaisquer alterações em tais informações pessoais.
        </p>

        <h2>Como usamos as informações</h2>
        <p>
          Usamos informações pessoais coletadas por meio de nosso website para uma variedade de fins
          comerciais. Processamos suas informações pessoais para esses fins com base em nossos
          interesses comerciais legítimos, a fim de celebrar ou executar um contrato com você, com o
          seu consentimento e/ou para o cumprimento de nossas obrigações legais.
        </p>
        <p>
          Indicamos os fundamentos de processamento específicos nos quais confiamos ao lado de cada
          propósito listado abaixo:
        </p>
        <p>
          <ul>
            <li>
              <b>Solicitar feedback: </b>
              Podemos usar suas informações para solicitar comentários e contatá-lo sobre o uso de
              nosso website;
            </li>
            <li>
              <b>Para gerenciar contas de usuários: </b>
              Podemos usar suas informações para fins de gerenciamento de nossa conta e mantê-la em
              funcionamento;
            </li>
            <li>
              <b>Para enviar informações administrativas para você: </b>
              Podemos usar suas informações pessoais para enviar informações sobre produtos, serviços
              e novos recursos e/ou informações sobre alterações em nossos termos, condições e
              políticas.
            </li>
            <li>
              <b>Para fazer cumprir nossos termos: </b>
              Condições e políticas para fins comerciais, para cumprir os requisitos legais e
              regulamentares ou em conexão com o nosso contrato.
            </li>
            <li>
              <b>Para responder às solicitações legais e prevenir danos: </b>
              Se recebermos uma intimação ou outra solicitação legal, podemos precisar inspecionar os
              dados que possuímos para determinar como responder.
            </li>
            <li>
              <b>Atenda e gerencie seus pedidos: </b>
              Podemos usar suas informações para cumprir e gerenciar seus pedidos e pagamentos.
            </li>
            <li>
              <b>Para entregar e facilitar a entrega de serviços ao usuário: </b>
              Podemos usar suas informações para fornecer o serviço solicitado.
            </li>
            <li>
              <b>Para responder às perguntas dos usuários e oferecer suporte aos usuários: </b>
              Podemos usar suas informações para responder às suas perguntas e resolver quaisquer
              problemas potenciais que você possa ter com o uso de nossos Serviços.
            </li>
          </ul>
        </p>

        <h2>Informações de menores</h2>
        <p>
          Não solicitamos dados ou comercializamos intencionalmente para crianças menores de 18 anos.
          Usando o website, você declara que tem pelo menos 18 anos ou que é o pai ou responsável por
          tal menor e consente com o uso do website. Se soubermos que informações pessoais de usuários
          menores de 18 anos foram coletadas, desativaremos a conta e tomaremos as medidas cabíveis
          para excluir imediatamente tais dados de nossos registros. Se você souber de quaisquer dados
          que possamos ter coletado de crianças menores de 18 anos, entre em contato conosco em {` `}
          <a href="mailto:dpo@roberty.app">dpo@roberty.app</a>.
        </p>

        <h2>Tratamento e dados compartilhados com terceiros</h2>
        <p>
          Podemos processar ou compartilhar seus dados que mantemos conforme bases legais abaixo
          descritas:
        </p>
        <p>
          <ul>
            <li>
              <b>Consentimento: </b>
              podemos processar seus dados se você nos der consentimento específico para usar suas
              informações pessoais para uma finalidade específica.
            </li>
            <li>
              <b>Obrigações legais ou regulatórias: </b>
              podemos divulgar suas informações quando formos legalmente obrigados a fazê-lo, a fim de
              cumprir a lei aplicável, solicitações governamentais, um processo judicial, ordem
              judicial ou processo legal, como em resposta a uma ordem judicial ou uma intimação
              (inclusive em resposta às autoridades públicas para atender aos requisitos de segurança
              nacional ou aplicação da lei).
            </li>
            <li>
              <b>Execução de um contrato: </b>
              Quando celebramos um contrato com você, podemos processar suas informações pessoais para
              cumprir os termos do nosso contrato.
            </li>
            <li>
              <b>Interesses legítimos: </b>
              podemos processar seus dados quando for razoavelmente necessário para atingir nossos
              interesses comerciais legítimos.
            </li>
            <li>
              <b>Interesses vitais: </b>
              podemos divulgar suas informações onde acreditamos ser necessário investigar, prevenir
              ou tomar medidas em relação a possíveis violações de nossas políticas, suspeita de
              fraude, situações que envolvam ameaças potenciais à segurança de qualquer pessoa e
              atividades ilegais, ou como evidência em litígios em que estamos envolvidos.
            </li>
          </ul>
        </p>
        <p>
          Mais especificamente, podemos precisar processar seus dados ou compartilhar suas informações
          pessoais nas seguintes situações:
        </p>
        <p>
          <ul>
            <li>
              <b>Transferências de negócios: </b>
              Podemos compartilhar ou transferir suas informações em conexão com, ou durante as
              negociações de, qualquer fusão, venda de ativos da empresa, financiamento ou aquisição
              de todo ou parte de nossos negócios para outra empresa.
            </li>
            <li>
              <b>Fornecedores, consultores e outros provedores de serviços terceirizados: </b>
              Podemos compartilhar seus dados com fornecedores terceirizados, provedores de serviços,
              empreiteiros ou agentes que prestam serviços para nós ou em nosso nome e requerem acesso
              a tais informações para fazer esse trabalho. Os exemplos incluem: processamento de
              pagamentos, análise de dados, entrega de e-mail, serviços de hospedagem, atendimento ao
              cliente e esforços de marketing. Podemos permitir que terceiros selecionados usem
              tecnologia de rastreamento no website, o que lhes permitirá coletar dados em nosso nome
              sobre como você interage com nosso website. Essas informações podem ser usadas para,
              entre outras coisas, analisar e rastrear dados, páginas ou recursos e compreender melhor
              a atividade online. A menos que descrito neste aviso, não compartilhamos, vendemos,
              alugamos ou trocamos nenhuma de suas informações com terceiros para fins promocionais.
            </li>
            <li>
              <b>Solicitação de análise de processo: </b>A fim de negociar e executar o contrato com
              você como cliente Roberty, processamos dados pessoais sobre você, como seu nome, cargo,
              empresa, endereço de e-mail, gravação da sua área de trabalho e outros dados pessoais
              necessários para fornecer nossos produtos e serviços e para se comunicar com você sobre
              esses produtos ou serviços.
            </li>
          </ul>
        </p>

        <h2>Como compartilhamos as suas informações</h2>
        <p>
          Classificamos cada parte para que você possa entender facilmente o propósito de nossas
          práticas de coleta e processamento de dados. Se processamos seus dados com base em seu
          consentimento e você deseja revogar seu consentimento, entre em contato conosco através do
          email <a href="mailto:dpo@roberty.app">dpo@roberty.app</a>
        </p>
        <p>
          Nós apenas compartilhamos e divulgamos suas informações com os seguintes terceiros:
        </p>
        <p>
          <ul>
            <li>
              <b>Serviços de computação em nuvem: </b>
              Amazon Web Services (AWS) e MongoDB Atlas;
            </li>
            <li>
              <b>Otimização de Funcionalidade e Infraestrutura: </b>
              Amazon Web Services (AWS).
            </li>
          </ul>
        </p>

        <h2>Utilização de cookies e outras tecnologias</h2>
        <p>
          Podemos usar cookies e tecnologias de rastreamento semelhantes (como web beacons) para
          acessar ou armazenar informações.
        </p>

        <h2>Por quanto tempo mantemos suas informações</h2>
        <p>
          Manteremos suas informações pessoais apenas durante o tempo necessário para os fins
          estabelecidos neste aviso de privacidade, a menos que um período de retenção mais longo seja
          exigido ou permitido por lei (como impostos, contabilidade ou outros requisitos legais).
          Nenhum propósito neste aviso exigirá que mantenhamos suas informações pessoais por mais
          tempo do que o período de tempo em que os usuários têm uma conta conosco.
        </p>
        <p>
          Quando não houver necessidade comercial legítima contínua de processar suas informações
          pessoais, excluiremos ou anonimizamos essas informações ou, se isso não for possível (por
          exemplo, porque suas informações pessoais foram armazenadas em arquivos de backup), o
          faremos com segurança armazene suas informações pessoais e isole-as de qualquer
          processamento posterior até que a exclusão seja possível.
        </p>

        <h2>Como garantimos a segurança das suas informações</h2>
        <p>
          Implementamos medidas de segurança técnicas e organizacionais apropriadas destinadas a
          proteger a segurança de qualquer informação pessoal que processamos. No entanto, apesar de
          nossas salvaguardas e esforços para proteger suas informações, nenhuma transmissão
          eletrônica pela Internet ou tecnologia de armazenamento de informações pode ser garantida
          como 100% segura, portanto, não podemos prometer ou garantir que hackers, cibercriminosos ou
          terceiros não autorizados não serão capaz de violar nossa segurança e coletar, acessar,
          roubar ou modificar indevidamente suas informações.
        </p>
        <p>
          Embora façamos o nosso melhor para proteger suas informações pessoais, a transmissão de
          informações pessoais de e para o nosso website é por sua própria conta e risco. Você só deve
          acessar o website dentro de um ambiente seguro.
        </p>

        <h2>Direitos do titular</h2>
        <p>
          De acordo com a Lei Geral de Proteção de Dados (LGPD) do Brasil, você tem o direito de
          confirmar que realizamos o tratamento de seus dados, acessar, corrigir, eliminar e portar
          seus dados. Em determinadas circunstancias, você também tem o direito de se opor e
          restringir o tratamento de seus dados pessoais. Nossa política de privacidade fornece
          informações detalhadas de como utilizamos e compartilhamos os seus dados.
        </p>
        <p>
          Se desejar, a qualquer momento, revisar ou alterar as informações em sua conta ou encerrar
          sua conta, você pode:
        </p>
        <p>
          <ul>
            <li>Faça login nas configurações de sua conta e atualize sua conta de usuário;</li>
            <li>Contate-nos usando as informações de contato fornecidas.</li>
          </ul>
        </p>
        <p>
          Mediante sua solicitação para encerrar sua conta, iremos desativar ou excluir sua conta e
          informações de nossos bancos de dados ativos. No entanto, podemos reter algumas informações
          em nossos arquivos para evitar fraudes, solucionar problemas, ajudar em quaisquer
          investigações, fazer cumprir nossos Termos de Uso e/ou cumprir os requisitos legais
          aplicáveis.
        </p>
        <p>
          <b>Cookies e tecnologias semelhantes:</b> a maioria dos navegadores da Web é configurada
          para aceitar cookies por padrão. Se preferir, geralmente você pode escolher configurar seu
          navegador para remover cookies e rejeitá-los. Se você escolher remover ou rejeitar cookies,
          isso pode afetar certos recursos ou serviços de nosso website.
        </p>
        <p>
          <b>Recurso de não rastrear, Do Not Track (DNT):</b> A maioria dos navegadores da web e
          alguns sistemas operacionais e aplicativos móveis incluem um recurso Não Rastrear ("DNT") ou
          configuração que você pode ativar para sinalizar sua preferência de privacidade de não ter
          dados sobre suas atividades de navegação online monitorados e coletados. Neste estágio,
          nenhum padrão de tecnologia uniforme para reconhecer e implementar sinais DNT foi
          finalizado. Como tal, atualmente não respondemos aos sinais do navegador DNT ou qualquer
          outro mecanismo que comunique automaticamente sua escolha de não ser rastreado online. Se
          for adotado um padrão para rastreamento online que devemos seguir no futuro, iremos
          informá-lo sobre essa prática em uma versão revisada deste aviso de privacidade.
        </p>

        <h2>Atualização da política</h2>
        <p>
          Podemos atualizar esta política de privacidade de tempos em tempos. A versão atualizada será
          indicada por uma data "Revisada" atualizada e a versão atualizada entrará em vigor assim que
          estiver acessível. Se fizermos alterações materiais a esta política de privacidade, podemos
          notificá-lo publicando em destaque um aviso de tais alterações ou enviando-lhe diretamente
          uma notificação. Nós o encorajamos a revisar este aviso de privacidade frequentemente para
          ser informado sobre como estamos protegendo suas informações.
        </p>

        <h2>Como rever, atualizar ou excluir os dados que coletamos</h2>
        <p>
          Com base nas leis aplicáveis do país, você pode ter o direito de solicitar acesso às
          informações pessoais que coletamos de você, alterar essas informações ou excluí-las em
          algumas circunstâncias. Para solicitar a revisão, atualização ou exclusão de suas
          informações pessoais, por favor envie e-mail para:
          {` `} <a href="mailto:dpo@roberty.app">dpo@roberty.app</a>. Responderemos à sua
          solicitação em até 30 dias.
        </p>

        <h2>Como nos contatar sobre esta política</h2>
        <p>
          Se você tiver perguntas ou comentários sobre esta política, você pode entre em contato
          conosco por e-mail em {` `}
          <a href="mailto:dpo@roberty.app">dpo@roberty.app</a>.
        </p>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy